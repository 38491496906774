import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  DocumentsApiService,
  DownloadTrackingConsentResponseDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { AnalyticsService } from '@ev-portals/cp/frontend/shared/util';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentTrackingEventsService {
  #documentsApiService = inject(DocumentsApiService);
  #analyticsService = inject(AnalyticsService);
  #authService = inject(AuthenticationService);

  $documentDownloadTrackingConsents = signal<DownloadTrackingConsentResponseDto | null>(null);
  $email = signal<string | undefined>(undefined);

  constructor() {
    this.#authService.user$.pipe(takeUntilDestroyed()).subscribe(user => {
      this.$email.set(user?.email);
    });
    this.#getDocumentDownloadConsents();
  }

  public trackDocumentDownloadEvent(
    payload: {
      documentName: string;
      productId?: string;
      productName?: string;
    },
    sbu: string | null,
  ): void {
    const userConsented = this.#getIfUserConsentedTrackingEmail(sbu);
    const { documentName, productId, productName } = payload;

    this.#analyticsService.trackEvent(
      'product-details',
      'documentDownloadClick',
      'download',
      JSON.stringify({
        documentName,
        productId,
        productName,
        email: userConsented ? this.$email() : undefined,
      }),
    );
  }

  public trackDocumentRequestClickEvent(payload: {
    documentName: string;
    productId: string;
    productName: string;
  }): void {
    const { documentName, productId, productName } = payload;

    this.#analyticsService.trackEvent(
      'product-details',
      'documentRequestClick',
      'action',
      JSON.stringify({
        documentName,
        productId,
        productName,
      }),
    );
  }

  #getDocumentDownloadConsents(): void {
    this.#documentsApiService
      .getUsersDocumentTrackingConsents()
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.$documentDownloadTrackingConsents.set(response);
        },
        error: () =>
          console.error(
            'Error fetching document download tracking consents. Document downloads will be tracked as anonymous.',
          ),
      });
  }

  #getIfUserConsentedTrackingEmail(sbu: string | null): boolean {
    if (!sbu) return false;

    const consents = this.$documentDownloadTrackingConsents();
    if (!consents) return false;

    // document download tracking is defined on a SBU level
    return consents.consentedSbusForDownloadTracking.includes(sbu);
  }
}
