import { Injectable } from '@angular/core';
import {
  AnnouncementApiService,
  SystemAnnouncementDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { BehaviorSubject, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  announcement$ = new BehaviorSubject<SystemAnnouncementDto | null>(null);

  constructor(private announcementApiService: AnnouncementApiService) {}

  checkAnnouncement(): void {
    this.announcementApiService
      .getSystemAnnouncement()
      .pipe(
        map(systemAnnouncementResponse => systemAnnouncementResponse.systemAnnouncement),
        first(),
      )
      .subscribe(announcement => {
        this.announcement$.next(announcement);
      });
  }
}
