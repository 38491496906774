import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

/**
 * The goal of this service is, to create a bridge between the 'product' and the 'purchase' domains
 * 'product' uses the addArticles method, to add articles ('product' -> 'shared')
 * 'purchase' uses cartItems$ to get notified about new items added to the cart ('purchase' -> 'shared')
 * This way, we can avoid dependencies between the 'product' and 'purchase' domains.
 */
@Injectable({
  providedIn: 'root',
})
export class CartProxyService {
  #_addNewCartItem$ = new ReplaySubject<CartItem>();
  addNewCartItem$ = this.#_addNewCartItem$.asObservable();
  addCartItem = (payload: { articleId: string; quantity: number; uom: string }): void =>
    this.#_addNewCartItem$.next(payload);

  #_numberOfAddedCartItems$ = new BehaviorSubject<number | undefined>(0);
  numberOfAddedCartItems$ = this.#_numberOfAddedCartItems$.asObservable();
  setNumberOfCartItems = (count: number | undefined): void =>
    this.#_numberOfAddedCartItems$.next(count);

  #_requestError$ = new Subject<string>();
  requestError$ = this.#_requestError$.asObservable();
  setRequestError = (errorMessage: string): void => this.#_requestError$.next(errorMessage);

  #_successMessage$ = new Subject<string>();
  successMessage$ = this.#_successMessage$.asObservable();
  setSuccessMessage = (message: string): void => this.#_successMessage$.next(message);
}

export interface CartItem {
  articleId: string;
  quantity: number;
  uom: string;
}
